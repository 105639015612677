.nav-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px;

  .right-side {
    a {
      margin-left: 15px;
      cursor: pointer;
      color: $teal;
      transition: 0.5s ease-in-out;

      &:hover {
        color: $dark-teal;
      }
    }
  }

  .left-side {
    display: flex;
  }

  .nav-link-wrapper {
    height: 22px;
    border-bottom: 1px solid transparent;
    transition: 0.5s ease-in-out;
    margin-right: 20px;
    text-transform: uppercase;
    font-size: 0.9rem;

    a {
      color: $grey;
      text-decoration: none;
      &:hover {
        color: black;
      }
    }

    &:hover {
      border-bottom: 1px solid black;
    }

    .nav-link-active {
      border-bottom: 1px solid black;
      color: black;
    }
  }
}
