.homepage-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    
    .filter-links {
        display: flex;
        justify-content: space-around;
    }

    .btn {
        width: 100%;
    }

}