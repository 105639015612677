.portfolio-detail-wrapper{
    width: 100vw;

    .banner{
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
    }
    
    .portfolio-detail-description-wrapper{
        text-align: center;
        margin-top: 42px;
        display: flex;
        justify-content: center;

        .description {
            width: 700px;
            font-size: 2em;
            margin-bottom: 50px;
        }
    }

    .bottom-content-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .site-link {
            padding: 15px;
            background-color: $teal;
            border-radius: 3px;
            text-decoration: none;
            color: white;
            transition: 0.5s ease-in-out;
            font-size: 1.2em;

            &:hover {
                background-color: $dark-teal;
            }
        }
    }
}