.portfolio-items-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  .portfolio-item-wrapper {
    position: relative;

    .portfolio-img-background {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 350px;
      width: 100%;
    }

    .img-text-wrapper {
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      text-align: center;
      padding-left: 100px;
      padding-right: 100px;

      .subtitle {
        transition: 1s ease-in-out;
        color: transparent;
      }
    }

    .img-text-wrapper:hover .subtitle {
      color: $teal;
      font-weight: 400;
    }

    .logo-wrapper img {
      width: 50%;
      margin-bottom: 20px;
    }
  }
}

.image-blur {
  transition: 1s;
  filter: brightness(10%);
}
