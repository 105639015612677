.content-page-wrapper {
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: calc(100vh - 84px);


    .right-column {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 100px;


        .contact-bullet-points {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 21px;

            .bullet-point-group {
                display: flex;
                align-items: center;


                .icon {
                    font-size: 2em;
                    color: $teal;
                }

                .text {
                    font-size: 1.5em;
                    font-weight: 900;
                    margin-left: 21px;
                }
            }
        }
    }

}

