.portfolio-sidebar-list-wrapper {
  .portfolio-item-thumb {
    padding: 21px;

    .portfolio-thumb-img img {
      width: 100%;
    }

    .text-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        color: $offwhite;
        font-size: 1.5em;
      }

      .actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;

        .action-icon {
          cursor: pointer;
          color: $offwhite;
          font-size: 1.5em;
          transition: 0.5s ease-in-out;

          &:hover {
            color: $warning;
          }
        }
      }
    }
  }
}
